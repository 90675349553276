import React from "react"
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import media from "styled-media-query";

import SEO from '../components/SEO'
import Layout from '../components/layout/layout'
import BanerProduct from '../components/banerProduct/BanerProduct';
import CardVertical, { CardWrapper } from "../components/cardVertical/CardVertical"
import { Container } from "../components/utils/utils"

const Article = styled(Container)`

  h2, h4 {
    margin: 0;
  }

  h2, h4, h5 {
    padding-bottom: 2rem;
  }

  h6 {
    padding-bottom: 0;
  }

  p {
    margin-top: 1rem;

    span {
      color: ${({ theme }) => theme.color.base};
      font-style: italic;
    }
  }

  >div {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;

const Section = styled.section`
  background-color: ${props => props.color || "transparent"};
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const Grid2 = styled(Container)`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-top: 4rem;

  div {
        width: 100% !important;
        padding: 0 !important;
    }

    img {
        position: relative !important;
        width: 100% !important;
        height: auto !important;
    }

  ${media.lessThan("medium")`
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto 2rem auto;
  `}

  ${media.greaterThan("medium")`
  grid-template-columns: repeat(2, 1fr);
  `}
`;

const Sdr = ({ data }) => {

  const sdr1 = data.sdr1.childImageSharp.fluid;
  const sdr2 = data.sdr2.childImageSharp.fluid;
  const sdr3 = data.sdr3.childImageSharp.fluid;
  const sdr4 = data.sdr4.childImageSharp.fluid;
  const sdr5 = data.sdr5.childImageSharp.fluid;
  const sdr6 = data.sdr6.childImageSharp.fluid;
  const sdr7 = data.sdr7.childImageSharp.fluid;
  const sdr8 = data.sdr8.childImageSharp.fluid;
  const sdr9 = data.sdr9.childImageSharp.fluid;
  const sdr10 = data.sdr10.childImageSharp.fluid;
  const sdr11 = data.sdr11.childImageSharp.fluid;
  const sdr12 = data.sdr12.childImageSharp.fluid;

  return <div>
    <SEO title="SDR Plus"
      slug='/sdr' />
    <Layout>

      <Section>
        <Article>
          <h2>Niezrównana technologia materiału SDR do wypełnień typu bulk fill</h2>
          <p>
            W 2009 płynny materiał SDR był pierwszym materiałem o technologii pozwalającej na aplikację w warstwach do 4 mm grubości. Po aplikacji ok. 50 milionów wypełnień i wielu długoterminowych badaniach kliniczych, SDR jest najczęściej wybieranym materiałem do szybkich i łatwych wypełnień bezpośrednich techniką bulk fill. Technologia SDR to odpowiedź na dwa wyzwania, jakim trzeba sprostać przy wypełnieniach bezpośrednich: adaptacja i efektywność.
        </p>
        </Article>
      </Section>

      <Section color={({ theme }) => theme.color.baseLight}>
        <Article>
          <h4>Adaptacja to klucz do długoterminowego powodzenia</h4>
          <p>
            Badania wskazują, że nieszczelność brzeżna może prowadzić do mikroprzecieków i pęknięć w obszarze ścian preparacji, czego efektem może być próchnica wtórna, co niweczy trwałość wypełnienia.
        </p>
          <ul>
            <li>Szczeliny i bruzdy pomiędzy wypełnieniem a ścianami ubytku wpływają na powstawanie próchnicy wtórnej.</li>
            <li>Niewłaściwa adaptacja kompozytu w trakcie jego aplikacji to przyczyna tworzenia się szczelin.</li>
            <li>Wysokie naprężenie skurczowe może prowadzić do powstania nieszczelności brzeżnej.</li>
          </ul>
        </Article>
      </Section>

      <Grid2>
        <div>
          <h5>+ Płynna konsystencja </h5>
          <p>
            Niepowtarzalny skład chemiczny materiału SDR pozwala zapewnić trwałość i wysoką jakość wypełnień wykonywanych techniką bulk fill. Płynna konsystencja SDR pozwala na uzyskanie perfekcyjnej adaptacji do ścianek ubytku, zapływając samoistnie we wszystkie szczeliny i bruzdy.
          </p>
        </div>

        <div>
          <h5>+ Niskie naprężenie skurczowe</h5>
          <p>
            Unikalna i opatentowana formuła SDR pozwala na kontrolowany przebieg polimeryzacji. Dołączony modulator polimeryzacji pomaga monomerom utworzyć bardziej elastyczną sieć polimerową, co kompensuje efekt skurczu polimeryzacyjnego, ograniczając siły naprężenia skurczowego.
          </p>
        </div>
      </Grid2>

      <Grid2>
        <Img
          fluid={sdr1}
          alt='SDR' />
        <Img
          fluid={sdr2}
          alt='SDR' />
      </Grid2>

      <Grid2>
        <div>
          <h4>Efektywność to klucz do wydajności</h4>
          <p>
            Wypełnienia bezpośrednie stanowią w przybliżeniu około jednej trzeciej rocznego obrotu gabinetu stomatologicznego. Zwiększenie efektywności wykonywania wypełnień ubytków klasy II ma bezpośredni wpływ na codzienne przychody kliniki. Technika wypełniania bulk fill ubytków materiałem SDR pozwala na aplikację materiału warstwami o grubości do 4 mm bez dodatkowego linera. Pozwala to oszczędzić czas zabiegu i zapasy magazynowe.
        </p>
          <ul>
            <li>Wypełnianie techniką bulk fill pozwala oszczędzić do 40% czasu w porównaniu z klasyczną techniką warstwową.</li>
            <li> Nie wymaga dodatkowego linera.</li>
            <li>Materiał sam się poziomuje i nie wymaga podejmowania dodatkowych działań w celu zapewnienia jego odpowiedniej adaptacji.</li>
          </ul>
        </div>

        <Img fluid={sdr3}
          alt='SDR' />

      </Grid2>

      <Section>
        <Article>
          <h2>Jak ulepszyć coś, co już jest najlepsze? Zwiększyć jego wszechstronność. </h2>
          <h5>Ulepszona formuła materiału SDR Plus jeszcze bardziej zwiększa jego wszechstronność, wprowadzając nowe odcienie i poszerzając zakres wskazań.</h5>
        </Article>
      </Section>

      <Grid2>
        <div>
          <h4>+ Więcej odcieni</h4>
          <p>
            Trzy nowe odcienie SDR Plus: A1, A2, A3 (obok odcienia uniwersalnego) zwiększają wszechstronność materiału dodatkowo w zakresie wskazań do odbudowy ubytków klasy III i V (przy dotychczasowych wskazaniach do klasy I i II) oraz uzupełnień w zębach mlecznych.
          </p>
        </div>
        <div>
          <h4>+ Więcej wskazań</h4>
          <p>
            Dzięki swojemu niepowtarzalnemu składowi chemicznemu i ulepszonej wytrzymałości zmęczeniowej, materiał SDR Plus został zarekomendowany do zastosowania w ubytkach klasy III i V, gdzie nie korzysta się z materiału do warstwy końcowej. Zwiększa to zakres możliwości wykorzystania materiału do większej liczby klas ubytków w porównaniu do jakiegokolwiek innego materiału o płynnej konsystencji.
          </p>
        </div>
      </Grid2>

      <Grid2>
        <Img
          fluid={sdr4}
          alt='SDR' />
        <Img
          fluid={sdr5}
          alt='SDR' />
      </Grid2>

      <Section color={({ theme }) => theme.color.baseLight}>
        <Article>
          <h4>Dodatkowe możliwości wykorzystania materiału SDR Plus:</h4>
        </Article>

        <CardWrapper>
          <CardVertical
            header2='+ Zęby mleczne w odcinku bocznym'
            fluid2={sdr6}>
            <p>
              SDR Plus może być wykorzystywany bez potrzeby dodatkowej warstwy końcowej i aplikowany do poziomu powierzchni okluzyjnej. Szybka i łatwa technika aplikacji to idealna metoda do zastosowania u dzieci.
            </p>
          </CardVertical>

          <CardVertical
            header2='+ Uszczelnianie bruzd'
            fluid2={sdr7}>
            <p>
              Materiał SDR Plus został uznany przez organizację CR Foundation za „znakomity” do uszczelniania bruzd. 7  Cienka kompiula pozwala na niezwykle precyzyjną aplikację materiału do wszelkich szczelin.
          </p>
          </CardVertical>

          <CardVertical
            header2='+ Odbudowa rdzenia pod stałe uzupełnienia protetyczne'
            fluid2={sdr8}>
            <p>
              Głębokość polimeryzacji do 4 mm powoduje, że SDR Plus staje się szybką i łatwą alternatywą dla odbudowy rdzenia, pozwalającą na bezproblemowe kształtowanie.
          </p>
          </CardVertical>
        </CardWrapper>
      </Section>

      <Section>
        <Article>
          <h2>Znakomita adhezja w zębach po leczeniu endodontycznym o wysokim współczynniku konfiguracyjnym</h2>
        </Article>
        <Grid2>
          <div>
            <h4>+ Mocne wiązanie</h4>
            <p>
              Uzupełnienia w zębach po leczeniu endodontycznym stanowią szczególne wyzwanie dla materiałów kompozytowych do wypełniania techniką bulk fill, ponieważ większość z nich to głębokie ubytki klasy I. Maksymalizuje to potencjał naprężenia skurczowego, określony jako wysoki współczynnik konfiguracyjny. Badania wskazują, że podczas wypełniania takich ubytków materiałem w technologii bulk fill, wybór kompozytu stanowi istotny element, pozwalający uniknąć rozszczelnienia.
            </p>
            <p>
              Materiał SDR Plus, wykorzystujący technologię SDR, doskonale radzi sobie z ubytkami o wysokim współczynniku konfiguracyjnym, zapewniając wysoką szczelność w odcinku koronowym, nawet w przypadku ubytków po leczeniu endodontycznym.
            </p>
          </div>
          <div>
            <p>Niepowodzenia we wstępnych badaniach (%) podczas testu siły wiązania na mikrorozciąganie.</p>
            <Img fluid={sdr9}
              alt='SDR Plus' />
          </div>

        </Grid2>

        <Grid2>
          <div>
            <h4>+ Szczelna odbudowa</h4>
            <p>
              „SDR Plus zapewnia wysoką szczelność wypełnień w ubytkach zębów leczonych endodontycznie, co jest istotnym czynnikiem warunkującym długoterminowy sukces leczenia endodontycznego.”
            </p>
          </div>
          <div>
            <p>Względna liczba (%) próbek wykazujących mikroprzeciek do kanału zęba.</p>
            <Img fluid={sdr10}
              alt='SDR Plus' />
          </div>

        </Grid2>

      </Section>

      <Section color={({ theme }) => theme.color.baseLight}>
        <Article>
          <h2>Wieloletnie sukcesy kliniczne</h2>
          <h5>Skracając czas i ułatwiając wykonywanie wypełnień ubytków klasy I i II, technologia SDR wykorzystana w materiale SDR Plus zapewnia trwałość potwierdzoną w kilku badaniach klinicznych. W rzeczywistości wskaźnik trwałości wypełnień wykonywanych techniką bulk fill nie odbiega od wyników wypełnień  wykonanych tradycyjną techniką warstwową. </h5>
          <h3>Badanie wg modelu „split mouth” Autorzy: J.W.V van Dijken oraz U. Pallesen</h3>

          <Grid2>
            <div>
              <h6>BADANIE KONTROLNE PO 6 LATACH (GRUPA 1: XENO V/SDR/CERAM·X) 10 JWV van Dijken i U. Pallesen</h6>
              <p>
                W badaniu porównano 49 par wypełnień klasy I i II materiałem SDR, wykonanymi techniką bulk fill w warstwie do 4 mm z taką samą liczbą wypełnień wykonanych materiałem ceram.x tradycyjną techniką warstwową.
              </p>
              <ul>
                Wnioski z przeprowadzonych badań:
                <li>Bezpieczny klinicznie.</li>
                <li>Bardzo satysfakcjonująca trwałość kliniczna.</li>
                <li>Skuteczność kliniczna i współczynnik niepowodzenia porównywalne z wypełnieniami wykonanymi tradycyjną techniką warstwową (3 niepowodzenia w grupie badanej i 3 w kontrolnej).</li>
              </ul>
            </div>
            <div>
              <Img fluid={sdr11}
                alt='SDR Plus' />
              <p>
                <span>
                  „W trakcie badania kontrolnego potwierdzono, że technika wypełniania bulk fill jest klinicznie bezpieczna, bardzo satysfakcjonująca i trwała.”
                </span>
              </p>
            </div>
          </Grid2>

          <Grid2>
            <div>
              <h6>BADANIE KONTROLNE PO 5 LATACH (GRUPA 2: XENO V+/SDR CERAM·X) JWV van Dijken i U. Pallesen 11</h6>
              <p>
                W badaniu porównano 100 wypełnień ubytków klasy I i II wykorzystujących SDR oraz ceram.x wykonanych techniką bulk fill z taką samą liczbą wypełnień wykonanych materiałem ceram.x techniką warstwową.
              </p>
              <ul>
                Wnioski z przeprowadzonych badań:
                <li>Obydwie techniki pozwoliły uzyskać dobrej jakości powierzchnię, szczelność brzeżną i stabilność koloru.</li>
                <li>Nie stwierdzono statystycznie istotnej różnicy pomiędzy roczną liczbą niepowodzeń pomiędzy techniką bulk fill a techniką warstwową.</li>
                <li>W żadnym przypadku nie stwierdzono nadwrażliwości pozabiegowej.</li>
              </ul>
            </div>

            <div>
              <Img fluid={sdr12}
                alt='SDR Plus' />
              <p>
                <span>
                  „Wykorzystanie techniki wypełniania bulk fill płynnym materiałem kompozytowym w warstwach o grubości do 4 mm do wypełnień ubytków w zębach bocznych wykazywało w trakcie badania kontrolnego po upływie 5 lat nieznacznie lepsze rezultaty (jednak w stopniu pozostającym bez znaczenia statystycznego) w porównaniu z tradycyjną techniką opierającą się na aplikacji warstw 2 mm.”
                </span>
              </p>
            </div>

          </Grid2>

          <Grid2>
            <div>
              <h6>REZULTATY BADAŃ KLINICZNYCH PO 36 MIESIĄCACH J. Burgess i C. Munoz 12</h6>
              <ul>
                <li>Nie stwierdzono niepowodzeń, które można przypisać SDR.</li>
                <li>Brak nadwrażliwości pozabiegowej.</li>
                <li>Brak negatywnego wpływu na przyzębie pozostające w kontakcie z SDR.</li>
              </ul>
            </div>
            <p><span>„Nie zaobserwowano próchnicy wtórnej charakterystycznej dla wykorzystania materiałów kompozytowych o obniżonym naprężeniu ani też żadnych niekorzystnych zdarzeń w całym okresie trwania badań” 12</span></p>
          </Grid2>
        </Article>
      </Section>

    </Layout>
  </div>
}

export default Sdr

export const query = graphql`
query {
  sdr2: file(relativePath: { eq: "sdr/sdr2.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr1: file(relativePath: { eq: "sdr/sdr1.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 500) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr3: file(relativePath: { eq: "sdr/sdr3.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr4: file(relativePath: { eq: "sdr/sdr4.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 500) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr5: file(relativePath: { eq: "sdr/sdr5.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr6: file(relativePath: { eq: "sdr/sdr6.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr7: file(relativePath: { eq: "sdr/sdr7.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 500) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr8: file(relativePath: { eq: "sdr/sdr8.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr9: file(relativePath: { eq: "sdr/sdr9.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr10: file(relativePath: { eq: "sdr/sdr10.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr11: file(relativePath: { eq: "sdr/sdr11.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 500) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  sdr12: file(relativePath: { eq: "sdr/sdr12.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

}
`