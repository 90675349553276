import React from 'react';
import styled from 'styled-components';
import media from "styled-media-query";
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Container } from '../utils/utils';
import Button from '../button/Button';

const Baner = styled(Container)`
background-color: #d7edf5;
min-height: 40vh;
margin-top: 3rem;
padding: 0;
text-align: left;
display: flex;
flex-direction: column;


> div {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
    
    ::before,
    ::after {
        background-size: contain !important;
        background-position-x: right !important;

        ${media.lessThan("medium")`
        display: none !important;
        `}
        }
}

h6 {
    text-transform: uppercase;
}

h2 {
  margin-bottom: 2rem;
}

p {
    line-height: 1.1;
    margin-bottom: .7rem;
    max-width: 500px;
    span {
        color: ${({ theme }) => theme.color.base};
        font-weight: 700;
    }
}
`;

const Image = styled(Img)`
    width: 100%;
    margin: 2rem auto;

    ${media.greaterThan("medium")`
        display: none !important;
        `}
`;

const BanerProduct = (props) => (
    <Baner
        backgroundColor={`#FFFFFF`}
        fluid={props.fluid}>
        <BackgroundImage
            fluid={props.fluid}>

            <div>
                <h6>{props.header1 || 'header1'}</h6>
                <h2>{props.header2 || 'header2'}</h2>
                {props.children}
            </div>

            <Image objectFit="contain"
                objectPosition="50% 50%"
                fluid={props.fluidMobile} />

            {props.button === undefined ? (
                <></>
            ) : (
                    <Button to={props.to}>
                        {props.button}
                    </Button>
                )}
        </BackgroundImage>
    </Baner>
);

export default BanerProduct;