import React from 'react';
import { Link } from 'gatsby'
import styled from 'styled-components';

const BtnA = styled.a`
    font-size: 20px;
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;
    padding: .4rem 2rem;
    background-color: ${({ theme }) => theme.color.base};
    transition: all .4s ease-in-out;

    &:hover {
        color: white;
        background-color: ${({ theme }) => theme.color.baseSoftDark};
    }
`;

const BtnL = styled(Link)`
    font-size: 20px;
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;
    padding: .4rem 2rem;
    background-color: ${({ theme }) => theme.color.base};
    transition: all .4s ease-in-out;

    &:hover {
        color: white;
        background-color: ${({ theme }) => theme.color.baseSoftDark};
    }
`;

const BtnB = styled.button`
    font-size: 20px;
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;
    padding: .4rem 2rem;
    background-color: ${({ theme }) => theme.color.base};
    transition: all .4s ease-in-out;

    &:hover {
        color: white;
        background-color: ${({ theme }) => theme.color.baseSoftDark};
    }
`;

const Button = ({ children, to, href, target }) => (
    <>
        {
            to ? (
                <BtnL to={to}>
                    {children}
                </BtnL>
            ) : (
                    href ? (
                        <BtnA href={href}
                            target={target}>
                            {children}
                        </BtnA>
                    ) : (
                            <BtnB>
                                {children}
                            </BtnB>))
        }
    </>
);

export default Button;