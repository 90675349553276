import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import { Container } from '../utils/utils'
import Button from '../button/Button';

export const CardWrapper = styled(Container)`
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 4rem;

    margin: 1rem auto 1rem auto;

    ${media.lessThan("small")`
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto 2rem auto;
    `}
 
    ${media.between("small", "medium")`
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto 2rem auto;
    `}

    ${media.between("medium", "large")`
    grid-template-columns: repeat(3, 1fr);
    `}

    ${media.between("large", "huge")`
    grid-template-columns: repeat(3, 1fr);
    `}
 
    ${media.greaterThan("huge")`
    grid-template-columns: repeat(3, 1fr);
    `}
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    h2, h6,li {
        white-space: pre-wrap !important;
    }

    ul,li {
        font-size: ${({ theme }) => theme.font.sizeSmall}
    }

    button, a {
        align-self: center;
    }

    > div {
        display: flex;
        flex-direction: column;
        span {
            min-height: 4rem;
        }
    }
    div + div{
            flex-grow: 1;
            justify-content: space-between;
        }
    
`;

const Image = styled(Img)`
    width: 100%;
    margin: 0 auto 2rem auto;
`;

const CardVertical = (props) => (
    <Card>
        <div>
            <span>
                <h4>{props.header1}</h4>
                <h6>{props.header2 || 'header2'}</h6>
            </span>

            <Image fluid={props.fluid} />
        </div>


        <div>
            {props.children}

            <Image fluid={props.fluid2} />

            {props.button === undefined ? (
                <></>
            ) : (
                    <Button
                        className="btn-center"
                        to={props.to}>
                        {props.button}
                    </Button>
                )}
        </div>


    </Card>
);

export default CardVertical;